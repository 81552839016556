<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
    persistent
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-pencil
        </v-icon> Update Details
      </h2>
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <v-container fluid>
          <v-card-text class="text-body-1 text-center">
            <v-row justify="center">
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="firstName"
                  :rules="[(v) => !!v || 'This field is required']"
                  label="First Name*"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="lastName"
                  :rules="[(v) => !!v || 'This field is required']"
                  label="Last Name*"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="mobileNumber"
                  :rules="[(v) => !!v || 'This field is required']"
                  label="Mobile Number*"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="email"
                  label="E-mail Address"
                  :rules="emailRules"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="type"
                  menu-props="auto"
                  :items="typeList"
                  label="Type*"
                  item-value="id"
                  item-text="name"
                  multiple
                  outlined
                  disabled
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="contractorCategory"
                  menu-props="auto"
                  :items="contractorCategories"
                  item-text="name"
                  item-value="id"
                  label="Contractor Category"
                  multiple
                  disabled
                  hint="Required only for type 'Contractor'"
                  persistent-hint
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="company"
                  menu-props="auto"
                  :items="companyList"
                  item-text="company_name"
                  item-value="id"
                  label="Company*"
                  :rules="[(v) => !!v || 'This field is required']"
                  append-icon="mdi-office-building-plus-outline"
                  outlined
                  dense
                  @click:append="handleIconClick"
                >
                  <template #append>
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-icon
                          v-on="on"
                          @click.stop="handleIconClick"
                        >
                          mdi-office-building-plus-outline
                        </v-icon>
                      </template>
                      <span>Add Company</span>
                    </v-tooltip>
                  </template>
                </v-select>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="country"
                  label="Country*"
                  outlined
                  dense
                  disabled
                />
              </v-col>
            </v-row>
            <p class="text-sm-left">
              *Indicates required field
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="red darken-3"
              @click="showModal = false"
            >
              cancel
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#FF3700"
              @click="updateServiceProvider()"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <create-company
      v-if="showForm"
      @closed="showForm = false"
      @company-created="fetchCompaniesList"
    />
  </v-dialog>
</template>
<script>
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import CreateCompany from 'src/views/dashboard/companies/CreateCompany';

  export default {
    name: 'EditServiceProvider',
    components: {
      'centre-spinner': spinner,
      'create-company': CreateCompany,
    },
    props: {
        serviceProviderId: {
          type: String,
          required: true,
        },
    },
    data () {
      return {
        showModal: true,
        firstName: '',
        lastName: '',
        mobileNumber: '',
        loading: false,
        isFormValid: false,
        email: null,
        contractorCategory: '',
        typeList: [
          {
            name: 'Contractor',
            id: 'contractor',
          },
          {
            name: 'Visitor',
            id: 'visitor',
          },
          {
            name: 'Staff',
            id: 'staff',
          },
        ],
        type: [],
        company: '',
        country: '',
        showForm: false,
      };
    },
    computed: {
      emailRules () {
        if (this.email !== null) {
          return [(v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'];
        } else {
          return [];
        }
      },
      contractorCategories () {
        return this.$store.getters['featuresettings/getContractorCategoryList'];
      },
      companyList () {
        return this.$store.getters['companies/getCompaniesList'];
      },
      serviceProviderDetails () {
        return this.$store.getters['serviceproviders/getServiceProviderDetails'];
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    async mounted () {
     await this.loadOnMount();
    },
    methods: {
      async loadOnMount () {
        this.loading = true;
        await this.fetchServiceProviderDetails();
        await this.$store.dispatch('featuresettings/fetchContractorCategoryList');
        await this.$store.dispatch('companies/fetchCompaniesList');
        await this.$store.dispatch('userprofile/fetchCountryCodes');
        this.loading = false;
      },
      async updateServiceProvider () {
        if (this.$refs.form.validate() === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        await this.$store.dispatch('serviceproviders/updateServiceProviderDetails', {
          updateDetails: {
            first_name: this.firstName,
            last_name: this.lastName,
            phone_number: this.mobileNumber,
            email_id: this.email,
            company_id: this.company,
          },
          serviceProviderId: this.serviceProviderId,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: ' Service provider updated successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.$emit('success');
          this.showModal = false;
        }).catch(() => {
          this.loading = false;
        });
      },
      handleIconClick () {
       this.showForm = true;
      },
      async fetchCompaniesList () {
        this.showForm = false;
        this.loading = true;
        await this.$store.dispatch('companies/fetchCompaniesList');
        this.loading = false;
      },
      async fetchServiceProviderDetails () {
        this.loading = true;
        this.$store.dispatch('serviceproviders/fetchServiceProviderDetails', {
          serviceProviderId: this.serviceProviderId,
        }).then(response => {
          this.firstName = this.serviceProviderDetails.first_name;
          this.lastName = this.serviceProviderDetails.last_name;
           this.type = this.serviceProviderDetails.type;
          this.mobileNumber = this.serviceProviderDetails.phone_number;
          this.email = this.serviceProviderDetails.email;
          this.contractorCategory = this.serviceProviderDetails.contractor_category.id;
          this.company = this.serviceProviderDetails.company.id;
          this.country = this.serviceProviderDetails.country_code;
          this.loading = false;
          }).catch(errors => {
            this.loading = false;
        });
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 21px;
}
.icon-style {
  color: #37474F;
  font-size: 55px;
  margin-right: 5px;
}
.my-container-style {
  padding-top: 24px;
}
</style>
