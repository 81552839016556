<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
    persistent
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-account
        </v-icon> Create Service Provider
      </h2>
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <v-container fluid>
          <v-card-text class="text-body-1 text-center">
            <v-row justify="center">
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="firstName"
                  :rules="[(v) => !!v || 'This field is required']"
                  label="First Name*"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="lastName"
                  :rules="[(v) => !!v || 'This field is required']"
                  label="Last Name*"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="mobileNumber"
                  :rules="[(v) => !!v || 'This field is required']"
                  label="Mobile Number*"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="email"
                  label="E-mail Address"
                  :rules="emailRules"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="type"
                  menu-props="auto"
                  :items="typeList"
                  label="Type*"
                  :rules="[(v) => !!v || 'This field is required']"
                  single-line
                  item-value="id"
                  item-text="name"
                  multiple
                  hint="Select all possible ways in which this provider can Check-In to your site."
                  persistent-hint
                  outlined
                  dense
                  @change="contractorCheck"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  ref="contractorCheck"
                  v-model="contractorCategory"
                  menu-props="auto"
                  :items="contractorCategories"
                  item-text="name"
                  item-value="id"
                  label="Contractor Category"
                  :rules="validationRules"
                  single-line
                  :disabled="isContractorSelected"
                  hint="Required only for type 'Contractor'"
                  persistent-hint
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="company"
                  menu-props="auto"
                  :items="companyList"
                  item-text="company_name"
                  item-value="id"
                  label="Company*"
                  :rules="[(v) => !!v || 'This field is required']"
                  single-line
                  append-icon="mdi-office-building-plus-outline"
                  outlined
                  dense
                  @click:append="handleIconClick"
                >
                  <template #append>
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-icon
                          v-on="on"
                          @click.stop="handleIconClick"
                        >
                          mdi-office-building-plus-outline
                        </v-icon>
                      </template>
                      <span>Add Company</span>
                    </v-tooltip>
                  </template>
                </v-select>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="country"
                  :items="countryList"
                  label="Country*"
                  :rules="[(v) => !!v || 'This field is required']"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <p class="text-sm-left">
              *Indicates required field
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="red darken-3"
              @click="showModal = false"
            >
              cancel
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#FF3700"
              @click="createServiceProvider()"
            >
              Create
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <create-company
      v-if="showForm"
      @closed="showForm = false"
      @company-created="fetchCompaniesList"
    />
  </v-dialog>
</template>
<script>
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import CreateCompany from 'src/views/dashboard/companies/CreateCompany';

  export default {
    name: 'CreateServiceProvider',
    components: {
      'centre-spinner': spinner,
      'create-company': CreateCompany,
    },
    data () {
      return {
        showModal: true,
        firstName: '',
        lastName: '',
        mobileNumber: '',
        loading: false,
        isFormValid: false,
        email: null,
        contractorCategory: '',
        typeList: [
          {
            name: 'Contractor',
            id: 'contractor',
          },
          {
            name: 'Visitor',
            id: 'visitor',
          },
          {
            name: 'Staff',
            id: 'staff',
          },
        ],
        type: [],
        company: '',
        country: '',
        showForm: false,
      };
    },
    computed: {
      emailRules () {
        if (this.email !== null) {
          return [(v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'];
        } else {
          return [];
        }
      },
      contractorCategories () {
        return this.$store.getters['featuresettings/getContractorCategoryList'];
      },
      companyList () {
        return this.$store.getters['companies/getCompaniesList'];
      },
      isContractorSelected () {
        if (this.type.includes('contractor')) {
          return false;
        }
        return true;
      },
      validationRules () {
        if (this.type.includes('contractor')) {
          return [(v) => !!v || 'This field is required'];
        } else {
          return [];
        }
      },
      CountryZipCodeList () {
        return this.$store.getters['userprofile/getCountryCodes'];
      },
      countryList () {
       return Object.keys(this.CountryZipCodeList).map((key) => ({ text: this.CountryZipCodeList[key], value: this.CountryZipCodeList[key] }));
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    async mounted () {
     await this.loadOnMount();
    },
    methods: {
      async loadOnMount () {
        this.loading = true;
        await this.$store.dispatch('featuresettings/fetchContractorCategoryList');
        await this.$store.dispatch('companies/fetchCompaniesList');
        await this.$store.dispatch('userprofile/fetchCountryCodes');
        this.loading = false;
      },
      async createServiceProvider () {
        if (this.$refs.form.validate() === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        await this.$store.dispatch('serviceproviders/createServiceProvider', {
          first_name: this.firstName,
          last_name: this.lastName,
          phone_number: this.mobileNumber,
          contractor_category_id: this.contractorCategory,
          email: this.email,
          types: this.type,
          company_id: this.company,
          country_code: this.country,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: ' New service provider created successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.$emit('success');
          this.showModal = false;
        }).catch(() => {
          this.loading = false;
        });
      },
      contractorCheck () {
        if (this.type.includes('contractor') === false) {
          this.$refs.contractorCheck.reset();
        }
      },
      handleIconClick () {
       this.showForm = true;
      },
      async fetchCompaniesList () {
        this.showForm = false;
        this.loading = true;
        await this.$store.dispatch('companies/fetchCompaniesList');
        this.loading = false;
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 21px;
}
.icon-style {
  color: #37474F;
  font-size: 55px;
  margin-right: 5px;
}
.my-container-style {
  padding-top: 24px;
}
</style>
