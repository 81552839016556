<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-account-group
      </v-icon> Service Providers
    </h2>
    <br>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <v-col
        class="text-right"
      >
        <v-btn
          color="#37474F"
          @click="groupServiceProviders()"
        >
          Group Users
        </v-btn>
        <v-btn
          color="#FF3700"
          @click="showForm = true"
        >
          Create New
        </v-btn>
      </v-col>
      <hr>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="serviceProviders"
        :search="search"
        class="elevation-1"
        disable-sort
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.first_name }} {{ item.last_name }}</td>
            <td>{{ item.phone_number }}  </td>
            <td>
              <span v-if="item.email !== null">{{ item.email }}</span>
              <span v-else>N/A</span>
            </td>
            <td>{{ item.company.company_name }}</td>
            <td>{{ item.country_code }}</td>
            <td>
              <span v-if="item.contractor_category !== null">{{ item.contractor_category.name }}</span>
              <span v-else>N/A</span>
            </td>
            <td>
              <span
                v-if="item.type !== null"
                class="type-style"
              > {{ item.type | arrangePretty }} </span>
              <span v-else>
                N/A
              </span>
            </td>
            <td>{{ item.created_at | convertToLocal }}</td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="updateServiceProviderDetails(item.external_id)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click.stop
                @click="getServiceProviderDetails(item.external_id)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <create-service-provider
      v-if="showForm"
      @closed="showForm = false"
      @success="getServiceProviders"
    />
    <edit-service-provider
      v-if="showEditForm"
      :service-provider-id="serviceProviderId"
      @closed="showEditForm = false"
      @success="getServiceProviders"
    />
  </v-container>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import CreateServiceProvider from 'src/views/dashboard/ServiceProviders/CreateServiceProvider';
  import moment from 'moment';
  import EditServiceProvider from 'src/views/dashboard/ServiceProviders/EditServiceProvider';

  export default {
    name: 'ServiceProviders',
    components: {
      'centre-spinner': spinner,
      'create-service-provider': CreateServiceProvider,
      'edit-service-provider': EditServiceProvider,
    },
    filters: {
      arrangePretty (val) {
        return val.join(', ');
      },
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
    },
    data () {
      return {
        search: '',
        showForm: false,
        loading: false,
        headers: [
          { text: 'Name', align: 'start', value: 'first_name' },
          { text: 'Phone', value: 'mobile_number' },
          { text: 'Email', value: 'email' },
          { text: 'Company', value: 'company' },
          { text: 'Country', value: 'country_code' },
          { text: 'Category', value: 'contractor_category' },
          { text: 'Check-Ins', value: 'contractor_type' },
          { text: 'Added On', value: 'created_at' },
          { text: 'Edit', value: 'edit' },
          { text: 'More', value: 'more' },
        ],
        showEditForm: false,
        serviceProviderId: '',
      };
    },
    computed: {
      serviceProviders () {
        return this.$store.getters['serviceproviders/getServiceProviders'];
      },
    },
    async mounted () {
      await this.getServiceProviders();
    },
    methods: {
      async getServiceProviders () {
        this.loading = true;
        await this.$store.dispatch('serviceproviders/fetchServiceProviders');
        this.loading = false;
      },
      getServiceProviderDetails (externalId) {
      this.$router.push(`/service-providers/${externalId}`);
     },
      groupServiceProviders () {
        this.$router.push({ name: 'Service Providers Structure' });
      },
      updateServiceProviderDetails (id) {
         this.serviceProviderId = id;
         this.showEditForm = true;
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.type-style {
  text-transform: capitalize;
}
</style>
